<template>
	<div>
		<b-modal
			id="modal-add-post-category"
			cancel-variant="outline-secondary"
			:ok-title="$t('Add')"
			:cancel-title="$t('Close')"
			ok-variant="success"
			centered
			modal-class="modal-success"
			:title="$t('Add post category')"
			@ok="confirmAdd"
			@hidden="resetModal()"
		>
			<!-- form -->
			<validation-observer ref="addPostCategoryModal">
				<b-form
					method="POST"
					class="auth-reset-password-form mt-2"
					@submit.prevent="validationForm"
				>
                    <!-- Field: Name -->
					<b-form-group
						label-for="name"
					>
						<label class="mb-1">{{ $t('Name') }}</label>
						<validation-provider
							#default="{ errors }"
							name="name"
						>
							<b-form-input
								id="name"
								v-model="postCategory.name"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
					<b-form-group
						label-for="name_en"
					>
						<label class="mb-1">{{ $t('English Name') }}</label>
						<validation-provider
							#default="{ errors }"
							name="name_en"
						>
							<b-form-input
								id="name_en"
								v-model="postCategory.name_en"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
							/>
							<small class="text-danger">{{ errors[0] }}</small>
						</validation-provider>
					</b-form-group>
				</b-form>
			</validation-observer>
		</b-modal>
	</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate/dist/vee-validate.full.esm'
import {
	BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, VBModal, BInputGroupAppend, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router'

export default {
	components: {
		BLink, BRow, BCol, BTabs, BTab, BForm, BFormGroup, BFormInput, BFormFile, BButton, BInputGroup, BInputGroupPrepend, BModal, BFormTextarea, BBadge,
		BInputGroupAppend,
		ValidationProvider,
		ValidationObserver,
		vSelect,
	},
	directives: {
		'b-modal': VBModal,
		Ripple,
	},
	props: {
	},
	data() {
		return {
			postCategory: {
                name: '',
			},
		}
	},
	methods: {
		validationForm() {
			this.$refs.addPostCategoryModal.validate().then(success => {
				if (success) {
					this.addPostCategory(this.postCategory)
				}
			})
		},
		async addPostCategory(postCategoryData) {
			await this.$store.dispatch('frontend/addPostCategory', postCategoryData)
				.then(response => {
					if (response.status === 200) {
						if (response.data.code == '00') {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message || 'success',
									icon: 'CheckIcon',
									variant: 'success',
								},
							})
                            this.$emit('refetch-data')
							this.$nextTick(() => {
								setTimeout(() => {
									this.$bvModal.hide('modal-add-post-category')
									this.resetModal()
								}, '500')
							})
							this.$emit('refetch-data')
						} else {
							this.$toast({
								component: ToastificationContent,
								props: {
									title: response.data.message,
									icon: 'AlertTriangleIcon',
									variant: 'danger',
								},
							})
						}
					}
				})
		},
		resetModal() {
		},
		confirmAdd(bvModalEvt) {
			bvModalEvt.preventDefault()
			this.validationForm()
		}
	},
	setup() {

		return {
		}
	},
}
</script>